.view-changes-legend {
  padding: 20px 20px 10px 20px;

  &__button {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;

    &.active {
      border: 3px solid lightblue;
    }
  }

  .view-changes-added {
    background-color: #758e4f;
  }
  .view-changes-deleted {
    background-color: #ad5d4e;
  }
  .view-changes-updated {
    background-color: #fbb13c;
  }
}
