@use 'sass:color';
.btn-primary {
  color: #fff;
  &:hover,
  &:active,
  &:disabled {
    color: #fff;
  }
}

.btn-lg {
  min-width: 100px;
}

.btn-rounded {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: $blue;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.1s ease-in;
  &:hover {
    color: #fff;
    background: color.adjust($blue, $lightness: -10%);
  }

  &.btn-rounded-sm {
    width: 28px;
    height: 28px;
  }
  &.btn-rounded-xsm {
    width: 21px;
    height: 21px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    &:hover {
      background: $blue;
    }
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: 0.4;
}

.swal2-actions {
  & > button {
    min-width: 100px;
  }
}

.swal2-validation-message {
  font-weight: 500;
}
