@use 'sass:color';
.modal__base {
  $color: #353d4a;
  display: none;
  border-radius: 5px;
  border: 1px solid color.adjust($color, $lightness: -70%);
  background-color: $color;
  box-shadow: '0 0 5px rgba(0, 0, 0, 0.3)';
  overflow: hidden;
  &.show {
    display: block;
    z-index: 999;
    right: 0;
  }

  & > * {
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: $blue;
    }
  }
}
