@use 'sass:color';

.nav-sidebar {
  position: fixed;
  height: calc(100vh - 80px);
  top: 80px;
  left: 0;
  width: 180px;
  background: #353d4a;
  overflow-y: auto;
  z-index: 10;
  -webkit-box-shadow: 13px -2px 17px -12px rgb(41 50 66 / 5%);
  box-shadow: 13px -2px 17px -12px rgb(41 50 66 / 5%);
  padding-top: 60px;

  display: flex;
  flex-direction: column;
}

.nav-sidebar__links {
  flex: 1 0 auto;
}

.nav-sidebar__link {
  display: block;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: #d1d5d6;

  > svg {
    margin-right: 10px;
    position: relative;
    top: -2px;
  }

  &:hover {
    background: #3a4250 !important;
    border-left: 4px solid #0895df !important;
    color: #d1d5d6;
  }
}

.nav-sidebar__link-icon {
  color: #d1d5d6;
}

.footer-sidebar {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5em;
}

.nav-sidebar__languages-btn {
  background: none;
  border: none;
  color: #0895df;
  &:hover,
  &:disabled {
    color: color.adjust(#0895df, $lightness: -10%);
  }
}
