@use 'sass:color';
.facility-form__voice-tooltip,
.facility-form__auto-priming-tooltip {
  display: inline-block;
  position: relative;
  top: -1px;
  left: 10px;
}

.facility-form__passcode {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.facility-form__basic-advisory-reason {
  display: block;
  text-decoration: none;
  background: #025cbd;
  border-radius: 15px;
  color: #fff;
  font-size: 2rem;
  padding: 10px 10px;
  text-align: center;
  position: relative;

  &.can-modify {
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: color.adjust(#025cbd, $lightness: -10%);

      .facility-form__reason-delete {
        display: inline-block;
      }
    }
  }
}

.facility-form__reason-delete {
  display: none;
  background: -webkit-linear-gradient(
    left,
    hsla(0, 0%, 97%, 0),
    #01438b 18%,
    #01438b 99%,
    #01438b
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(hsla(0, 0%, 97%, 0)),
    color-stop(18%, #01438b),
    color-stop(99%, #01438b),
    to(#01438b)
  );
  background: -o-linear-gradient(
    left,
    hsla(0, 0%, 97%, 0) 0,
    #01438b 18%,
    #01438b 99%,
    #01438b 100%
  );
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 97%, 0) 0,
    #01438b 18%,
    #01438b 99%,
    #01438b
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0001438B",endColorstr="#01438B",GradientType=1);
  border: none;
  color: #f7f7f7;
  position: absolute;
  right: 15px;
  top: 5px;
  padding-left: 10px;

  &:hover {
    svg {
      color: #fff;
    }
  }
}

.facility-form__voice-switcher,
.facility-form__auto-priming-switcher {
  display: inline-block;
  padding-left: 30px;
  top: 6px;
  position: relative;
}

.facility-form__edit-title {
  position: relative;
  top: -5px;
  left: 10px;
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: #0895df;
  }
}
