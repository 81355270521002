@use 'sass:color';
.color-bg {
  background: #313844;
}

.tiny-labels {
  color: $blue;
  font-weight: bold;
  /*   font-size: 0.8rem; */
}

.button {
  text-decoration: none;
  color: $white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.1s ease-in;
  &:hover {
    background: color.adjust($blue, $lightness: -10%);
    color: $white;
  }
}

.sky-blue {
  background-color: $blue;
  font-weight: bold;
}
